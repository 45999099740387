.hero-slider .item {
  position: relative;
}

.booking-form {
  background-color: white;
  margin-left: -20%;
  margin-right: -20%;
  border-radius: 5px;
}

.booking-form label {
  color: white;
}

.booking-form input,
.booking-form select,
.booking-form button {
  width: 100%;
  margin-bottom: 10px;
}

/* Navbar container styles */
.navbar-container {
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: top 0.3s;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-brand {
  color: #fff;
  text-decoration: none;
}

.navbar-logo {
  height: 70px;
  width: 70px;
}

/* Toggler button styles */
.navbar-toggler {
  background-color: #fff;
  border: none;
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.navbar-toggler-icon {
  width: 25px;
  height: 2px;
  background-color: #000;
  display: block;
  margin: 3px 0;
}

/* Navbar menu styles */
.navbar-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-item {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-item:hover,
.dropdown-item:hover {
  background-color: #b8a680;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #343a40;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
}

/* Show dropdown menu on hover */
.dropdown:hover .dropdown-menu,
.dropdown-menu.open {
  display: flex;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-toggler {
    display: flex;
  }

  .navbar-menu {
    flex-direction: column;
    display: none;
    width: 100%;
  }

  .navbar-menu.open {
    display: flex;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    width: 100%;
  }

  .dropdown-item {
    width: 100%;
    text-align: center;
  }

  .ff{
    width: 80%;
    height: 5%;
    margin-top: 35px;
  }

  .fff{
    margin-top: 35px;
  }
}